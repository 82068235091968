// react/gatsby imports
import React from "react";
// assets
import ogImage from "assets/images/seo/index-main.jpg";
import ogImageVK from "assets/images/seo/vk/index-main.jpg";
// components
import Layout from "components/layout";
import SEO from "components/layout/seo";
import NotFound from "components/pages/404";

const NotFoundPage = () => (
  <Layout>
    <SEO title="Страница не найдена" ogImage={ogImage} ogImageVk={ogImageVK} />
    <div className="section-top-block" />
    <NotFound />
  </Layout>
);

export default NotFoundPage;
